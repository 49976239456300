<template>
  <div v-if="namaGuru" class="scheduleTeacherAdmin">
    <a-row>
      <a-col :span="24">
        <h1>Schedule for {{namaGuru}}</h1>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <div class="card-full">
          <div class="detail">
            <BoxScheduleAdmin :namaGuru='namaGuru' :slots="allSlot" :week="currentWeek" />
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import BoxScheduleAdmin from '@/components/app/Admin/BoxScheduleAdmin'
import moment from 'moment'
export default {
  data() {
    return {
      namaGuru: '',
      allSlot: [],
      currentWeek: null,
    }
  },
  components: {
    BoxScheduleAdmin,
  },
  methods: {
  },
  created() {
    this.$store.dispatch('admin/FETCH_SCHEDULE_TEACHER', { idGuru: this.$route.params.id })
      .then(({ namaGuru, allSlot }) => {
        this.namaGuru = namaGuru
        this.allSlot = allSlot
      })
    this.currentWeek = moment().diff(this.semesterFirstDay, 'weeks') + 1
  },
  computed: {
    semesterFirstDay() {
      return this.$store.state.slot.semesterFirstDay
    },
  },
}
</script>

<style lang="scss">
.scheduleTeacherAdmin {
  h1 {
    margin: 0!important;
  }

  .card-full {
    padding: 20px 20px;
    margin-top: 60px;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 0px 10px #0419301a;
  }
}
</style>
