<template>
  <div
    @mouseleave="isHover = false"
    @mouseenter="isHover = !isHover"
    class="boxScheduleAdmin"
    :style="{
      padding: isMobile
        ? n === 5 || n === 10
          ? 0
          : isHover
          ? 0
          : ''
        : (n > 20 && n <= 25) || (n > 45 && n <= 50) || isHover
        ? 0
        : '',
      border: isMobile
        ? n === 5 || n === 10
          ? 0
          : isHover
          ? 0
          : ''
        : (n > 20 && n <= 25) || (n > 45 && n <= 50) || isHover
        ? 'none'
        : '',
    }"
    :key="n"
  >
    <a-modal
      :visible="modalEdit"
      @cancel="modalEdit = false"
      @ok="confirmEdit"
      ok-text="Edit"
      :confirm-loading="loadingConfirmEdit"
    >
      <h3 slot="title" style="line-height: 1.7rem">
        Are you sure to edit {{ namaGuru }} schedule?
      </h3>
      <div>
        this schedule then will be added on each week's semester with fixed
        topics.
      </div>
    </a-modal>
    <a-modal
      :visible="modalConfrim"
      @cancel="modalConfirm = false"
      @ok="confirmAdd"
      ok-text="Add"
      :confirm-loading="loadingConfirmAdd"
    >
      <h3 slot="title" style="line-height: 1.7rem">
        Are you sure to add new schedule for {{ namaGuru }}?
      </h3>
      <div>
        this schedule then will be added on each week's semester with fixed
        topics.
      </div>
    </a-modal>
    <a-modal
      :visible="visibleEditSlot"
      title="Edit slot"
      okText="Edit"
      @cancel="handleCancelEdit"
      @ok="handleOkEdit"
    >
      <a-form layout="vertical">
        <a-input-group size="large">
          <a-row :gutter="8">
            <a-col :span="8">
              <a-form-item label="Class">
                <a-input :value="inputClassEdit" disabled />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="Day">
                <a-select
                  :value="inputDayEdit"
                  size="large"
                  placeholder="Choose Day"
                  style="width: 100%; height: 48px"
                  @change="handleSelectDayEdit"
                >
                  <a-select-option
                    v-for="day in days"
                    :key="day"
                    :value="day"
                    >{{ day }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="Subject">
                <a-input :value="inputSubjectEdit" disabled />
              </a-form-item>
            </a-col>
          </a-row>
        </a-input-group>
        <a-input-group size="large">
          <a-row :gutter="8">
            <a-col :span="8">
              <a-form-item label="Start">
                <a-select
                  size="large"
                  placeholder="Choose Start Time"
                  style="width: 100%; height: 48px"
                  @change="(value) => handleSelectStartEdit(value)"
                  :value="inputStartEdit"
                >
                  <template v-for="(time, i) in times">
                    <a-select-option
                      v-if="i !== 4 && i !== 9"
                      :key="i"
                      :value="`${i + 1}-${time}`"
                      >{{ time }}</a-select-option
                    >
                  </template>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="Class Hour">
                <a-select
                  :value="inputClassHourEdit"
                  size="large"
                  placeholder="Choose Class Hour"
                  style="width: 100%; height: 48px"
                  @change="handleSelectClassHourEdit"
                >
                  <a-select-option
                    v-for="n in classHours"
                    :key="n"
                    :value="n"
                    >{{ n }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="End">
                <a-input :value="jamSelesaiEdit" disabled />
              </a-form-item>
            </a-col>
          </a-row>
        </a-input-group>
        <a-input-group size="large">
          <a-row :gutter="8">
            <a-col :span="24">
              <a-form-item label="Teacher">
                <a-select
                  size="large"
                  placeholder="Choose Teacher"
                  style="width: 100%; height: 48px"
                  @change="changeInputTeacherEdit"
                  :value="inputTeacherEdit"
                >
                  <a-select-option
                    v-for="guru in guruEdit"
                    :key="guru.id"
                    :value="guru.id"
                    >{{ guru.nama }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-input-group>
      </a-form>
    </a-modal>
    <a-modal
      :visible="visible"
      title="Create new slot"
      okText="Create"
      @cancel="handleCancel"
      @ok="handleCreate"
      :okButtonProps="{
        props: {
          disabled: loadingAdd,
        },
      }"
      :cancelButtonProps="{
        props: {
          disabled: loadingAdd,
        },
      }"
    >
      <div
        v-if="loadingAdd"
        style="
          display: flex;
          justify-content: center;
          height: 30vh;
          align-items: center;
        "
      >
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 5em; color: #008080"
            spin
          />
        </a-spin>
      </div>
      <a-form layout="vertical" v-else>
        <a-input-group size="large">
          <a-row :gutter="8">
            <a-col :span="24">
              <a-form-item label="Day">
                <a-input :value="day" disabled />
              </a-form-item>
            </a-col>
          </a-row>
        </a-input-group>
        <a-input-group size="large">
          <a-row :gutter="8">
            <a-col :span="8">
              <a-form-item label="Start">
                <a-input :value="desc.jamMulai" disabled />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="Class Hour">
                <a-select
                  size="large"
                  placeholder="Choose Class Hour"
                  style="width: 100%; height: 48px"
                  @change="handleSelectClassHour"
                  default-value="1"
                  :value="inputClassHour"
                >
                  <a-select-option
                    v-for="n in classHours"
                    :key="n"
                    :value="n"
                    >{{ n }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="End">
                <a-input :value="jamSelesai" disabled />
              </a-form-item>
            </a-col>
          </a-row>
        </a-input-group>
        <a-input-group>
          <a-row>
            <a-col :span="10">
              <a-form-item required label="Class" has-feedback>
                <a-select
                  size="large"
                  placeholder="Select Class"
                  style="width: 100%; height: 48px"
                  @change="handleSelectClass"
                >
                  <a-select-option
                    v-for="item in kelas"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.tingkat }}-{{ item.simbol }}</a-select-option
                  >
                </a-select>
                <small
                  v-if="validationAdd.class"
                  style="position: relative; color: red"
                  >Class is required!</small
                >
              </a-form-item>
            </a-col>
            <a-col :span="2" />
            <a-col :span="12">
              <a-form-item
                required
                label="Subject"
                :style="{
                  marginBottom: validationAdd.subject ? '' : '19%',
                }"
              >
                <a-select
                  size="large"
                  placeholder="Select Subject"
                  style="width: 100%; height: 48px"
                  @change="handleSelectSubject"
                >
                  <a-select-option
                    v-for="item in mapel"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.nama }}</a-select-option
                  >
                </a-select>
                <small
                  v-if="validationAdd.subject"
                  style="position: relative; color: red"
                  >Subject is required!</small
                >
              </a-form-item>
            </a-col>
          </a-row>
        </a-input-group>
      </a-form>
    </a-modal>
    <div
      class="break"
      v-if="
        (!isMobile && n > 20 && n <= 25) ||
        (n > 45 && n <= 50) ||
        (isMobile && (n === 5 || n === 10))
      "
    >
      <!-- break -->
    </div>
    <template v-else>
      <template v-if="slots.length > 0">
        <template>
          <template v-for="(slot, idxSlot) in slots">
            <template v-for="(session, idx) in slot.sesis">
              <div
                v-if="
                  (!isMobile &&
                    n % 5 === slot.hari &&
                    Math.ceil(n / 5) === slot.posisi) ||
                  (isMobile &&
                    dayInputMobile === slot.hari &&
                    n === slot.posisi)
                "
                class="boxSlot"
                @mouseenter="showIcon = true"
                @mouseleave="showIcon = false"
                :key="`${idxSlot} dan ${idx}`"
              >
                <a-row type="flex" align="middle">
                  <a-col :span="15">
                    <h3>{{ slot.kela.tingkat }}-{{ slot.kela.simbol }}</h3>
                    <div class="subH3">{{ slot.mata_pelajaran.nama }}</div>
                  </a-col>
                  <a-col :span="9" v-if="showIcon && !isRepeated">
                    <p
                      class="buttonSlot mb-2"
                      style="color: #1b55e3"
                      @click.prevent="
                        showModalEdit(
                          slot.mata_pelajaran,
                          slot.kela,
                          slot.id,
                          slot.hari,
                          slot.posisi,
                          slot.jam_pelajaran
                        )
                      "
                    >
                      <a-icon type="edit" />
                      <span class="textButtonSlot"> Edit</span>
                    </p>
                    <p
                      @click.prevent="deleteSlot(slot.id, slot)"
                      class="buttonSlot"
                      style="color: #ff0000"
                    >
                      <a-icon type="delete" />
                      <span class="textButtonSlot"> Delete </span>
                    </p>
                  </a-col>
                </a-row>
              </div>
            </template>
          </template>
        </template>
      </template>
      <transition name="slide-fadein-right" mode="out-in">
        <div
          v-if="isHover && isEmpty"
          class="hoverSlot"
          @click.prevent="showModal"
        >
          <a-icon
            class="animationPlus"
            type="plus-circle"
            style="font-size: 40px; color: white"
          />
        </div>
      </transition>
    </template>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  props: ['n', 'isEmpty', 'desc', 'slots', 'times', 'namaGuru', 'isMobile', 'dayInputMobile'],
  data() {
    return {
      topic: [],
      inputClass: null,
      inputSubject: null,
      inputClassHour: 1,
      isHover: false,
      showIcon: false,
      visible: false,
      visibleEditSlot: false,
      inputSubjectEdit: null,
      inputDayEdit: null,
      inputStartEdit: null,
      inputClassHourEdit: null,
      inputTeacherEdit: null,
      inputClassEdit: null,
      currentEditSlot: null,
      positionEdit: null,
      idKelasEdit: null,
      currentClassHoursEdit: null,
      kelas: [],
      mapel: [],
      guruEdit: [],
      jamSelesai: moment(this.desc.jamMulai, 'HH:mm').add(this.desc.durasi * 1, 'minutes').format('HH:mm'),
      jamSelesaiEdit: null,
      days: ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat'],
      loadingAdd: false,
      loadingConfirmAdd: false,
      modalConfrim: false,
      modalEdit: false,
      loadingConfirmEdit: false,
      isRepeated: false,
      validationAdd: {
        class: false,
        subject: false,
      },
    }
  },
  methods: {
    deleteSlot(idSlot, slot) {
      this.$confirm({
        title: 'Delete Schedule',
        content: (
          <div>
            <div>Are you sure to delete schedule {slot.mata_pelajaran.nama} on {slot.kela.tingkat}-{slot.kela.simbol} ( {this.day}, {this.desc.jamMulai} ) ?</div>
            <small style="color: red;">Once deleted, you can't bring the schedule back.</small>
          </div>
        ),
        onOk: () => {
          this.$store.dispatch('admin/DELETE_SLOT', {
            idSlot,
          })
            .then(_ => {
              window.location.reload()
            })
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
      })
    },
    changeInputTeacherEdit(value) {
      const sameWithFirst = this.inputDayEdit === this.day && Number(this.inputStartEdit.split('-')[0]) === this.desc.position && Number(this.inputClassHourEdit) === this.desc.jamPelajaran && Number(value) === Number(this.$route.params.id)
      if (sameWithFirst) {
        this.inputTeacherEdit = value
      } else {
        this.$store.dispatch('admin/VALIDATE_EDIT_SLOT', {
          idGuru: value,
          tipeModelJadwal: this.desc.tipe_model_jadwal,
          durasi: this.desc.durasi,
          jamPelajaran: this.inputClassHourEdit,
          jamMulai: this.inputStartEdit.split('-')[1],
          idKelas: this.inputClass,
          posisi: this.inputStartEdit.split('-')[0],
          hari: this.day,
        })
          .then(data => {
            if (data) {
              this.inputTeacherEdit = value
            } else {
              this.$notification.error({
                message: 'Error',
                description:
                  'The teacher already has schedule on that time',
              })
            }
          })
      }
    },
    handleSelectDayEdit(value) {
      const sameWithFirst = value === this.day && Number(this.inputStartEdit.split('-')[0]) === this.desc.position && Number(this.inputClassHourEdit) === this.desc.jamPelajaran && Number(this.inputTeacherEdit) === Number(this.$route.params.id)
      if (sameWithFirst) {
        this.inputDayEdit = value
      } else {
        let classCheckIsNotValidate = false
        this.$store.dispatch('admin/VALIDATE_EDIT_SLOT', {
          idGuru: this.inputTeacherEdit,
          tipeModelJadwal: this.desc.tipe_model_jadwal,
          durasi: this.desc.durasi,
          jamPelajaran: this.inputClassHourEdit,
          jamMulai: this.inputStartEdit.split('-')[1],
          idKelas: this.inputClass,
          posisi: this.inputStartEdit.split('-')[0],
          hari: value,
        })
          .then(data => {
            if (data) {
              return this.$store.dispatch('admin/VALIDATION_CLASS_SLOT', {
                tipeModelJadwal: this.desc.tipe_model_jadwal,
                jamMulai: this.inputStartEdit.split('-')[1],
                jamPelajaran: this.inputClassHourEdit,
                idKelas: this.idKelasEdit,
                posisi: this.inputStartEdit.split('-')[0],
                hari: value,
              })
            } else {
              classCheckIsNotValidate = true
            }
          })

          .then(data => {
            if (data) {
              this.inputDayEdit = value
            } else {
              this.$notification.error({
                message: 'Error',
                description:
                  classCheckIsNotValidate ? 'The teacher already has schedule on that time' : 'The class already has schedule on that time',
              })
            }
          })
      }
    },
    handleSelectStartEdit(value) {
      const position = value.split('-')[0]
      const time = value.split('-')[1]
      const sameWithFirst = this.inputDayEdit === this.day && Number(value.split('-')[0]) === this.desc.position && Number(this.inputClassHourEdit) === this.desc.jamPelajaran && Number(this.inputTeacherEdit) === Number(this.$route.params.id)
      if (sameWithFirst || (this.inputDayEdit === this.day && this.desc.jamPelajaran === 2 && this.inputClassHourEdit === 1 && Number(value.split('-')[0]) === this.desc.position + 1)) {
        this.positionEdit = position
        this.inputStartEdit = value
        this.jamSelesaiEdit = moment(time, 'HH:mm').add(this.desc.durasi * this.inputClassHourEdit, 'minutes').format('HH:mm')
      } else {
        let classCheckIsNotValidate = false
        this.$store.dispatch('admin/VALIDATE_EDIT_SLOT', {
          idGuru: this.inputTeacherEdit,
          tipeModelJadwal: this.desc.tipe_model_jadwal,
          durasi: this.desc.durasi,
          jamPelajaran: this.inputClassHourEdit,
          jamMulai: time,
          idKelas: this.inputClass,
          posisi: position,
          hari: this.inputDayEdit,
        })
          .then(data => {
            if (data) {
              return this.$store.dispatch('admin/VALIDATION_CLASS_SLOT', {
                tipeModelJadwal: this.desc.tipe_model_jadwal,
                jamMulai: time,
                jamPelajaran: this.inputClassHourEdit,
                idKelas: this.idKelasEdit,
                posisi: position,
                hari: this.inputDayEdit,
              })
            } else {
              classCheckIsNotValidate = true
            }
          })
          .then(data => {
            if (data) {
              this.positionEdit = position
              this.inputStartEdit = `${position}-${time}`
              this.jamSelesaiEdit = moment(time, 'HH:mm').add(this.desc.durasi * this.inputClassHourEdit, 'minutes').format('HH:mm')
            } else {
              this.$notification.error({
                message: 'Error',
                description:
                  classCheckIsNotValidate ? 'The teacher already has schedule on that time' : 'The class already has schedule on that time',
              })
            }
          })
      }
    },
    handleSelectClassHourEdit(value) {
      const addBreak = this.inputStartEdit.split('-')[0] === 4 && value === 2 ? 15 : 0
      // console.log(this.inputStartEdit.split('-')[0], 'll', this.desc.position)
      const sameWithFirst = this.inputDayEdit === this.day && Number(this.inputStartEdit.split('-')[0]) === this.desc.position && Number(value) === this.desc.jamPelajaran && Number(this.inputTeacherEdit) === Number(this.$route.params.id)
      if (sameWithFirst || (this.inputDayEdit === this.day && Number(this.inputStartEdit.split('-')[0]) === this.desc.position)) {
        this.inputClassHourEdit = value
        this.jamSelesaiEdit = moment(this.inputStartEdit.split('-')[1], 'HH:mm').add((this.desc.durasi * this.inputClassHourEdit) + addBreak, 'minutes').format('HH:mm')
      } else {
        let classCheckIsNotValidate = false
        this.$store.dispatch('admin/VALIDATE_EDIT_SLOT', {
          idGuru: this.inputTeacherEdit,
          tipeModelJadwal: this.desc.tipe_model_jadwal,
          durasi: this.desc.durasi,
          jamPelajaran: value,
          jamMulai: this.inputStartEdit.split('-')[1],
          idKelas: this.inputClass,
          posisi: this.inputStartEdit.split('-')[0],
          hari: this.inputDayEdit,
        })
          .then(data => {
            if (data) {
              const jamPelajaranSelanjutnya = moment(this.inputStartEdit.split('-')[1], 'HH:mm').add((Number(this.desc.durasi) + addBreak), 'minutes').format('HH:mm')
              // console.log(jamPelajaranSelanjutnya, value, this.idKelasEdit, this.inputDayEdit, this.desc.tipe_model_jadwal, Number(this.inputStartEdit.split('-')[0]) + 1)
              return this.$store.dispatch('admin/VALIDATION_CLASS_SLOT', {
                tipeModelJadwal: this.desc.tipe_model_jadwal,
                jamMulai: jamPelajaranSelanjutnya,
                jamPelajaran: value,
                idKelas: this.idKelasEdit,
                posisi: value === 2 ? Number(this.inputStartEdit.split('-')[0]) + 1 : this.inputStartEdit.split('-')[0],
                hari: this.inputDayEdit,
              })
            } else {
              classCheckIsNotValidate = true
            }
          })
          .then(data => {
            if (data) {
              this.inputClassHourEdit = value
              this.jamSelesaiEdit = moment(this.inputStartEdit.split('-')[1], 'HH:mm').add((this.desc.durasi * this.inputClassHourEdit) + addBreak, 'minutes').format('HH:mm')
            } else {
              this.$notification.error({
                message: 'Error',
                description:
                  classCheckIsNotValidate ? 'The teacher already has schedule on that time' : 'The class already has schedule on that time',
              })
            }
          })
      }
    },
    showModalEdit(mapel, kelas, idSlot, hari, posisi, jamPelajaran) {
      this.currentEditSlot = idSlot
      this.$store.dispatch('admin/GET_TEACHER_BY_ID_MAPEL', { idMapel: mapel.id })
        .then(gurus => {
          this.guruEdit = gurus
          gurus.forEach(guru => {
            if (guru.id === parseInt(this.$route.params.id)) {
              this.inputTeacherEdit = guru.id
            }
          })
          this.idKelasEdit = kelas.id
          this.inputClassEdit = `${kelas.tingkat}-${kelas.simbol}`
          this.inputSubjectEdit = mapel.nama
          this.inputStartEdit = `${posisi}-${this.desc.jamMulai}`
          this.visibleEditSlot = true
          this.inputClassHourEdit = jamPelajaran
          this.currentClassHoursEdit = jamPelajaran
          this.positionEdit = posisi
          this.inputDayEdit = this.day
          this.jamSelesaiEdit = moment(this.inputStartEdit.split('-')[1], 'HH:mm').add(this.desc.durasi * this.inputClassHourEdit, 'minutes').format('HH:mm')
        })
    },
    confirmEdit() {
      this.loadingConfirmEdit = true
      this.$store.dispatch('admin/UPDATE_SLOT', {
        idSlot: this.currentEditSlot,
        slot: {
          hari: this.inputDayEdit,
          id_guru: this.inputTeacherEdit,
          posisi: this.positionEdit,
          jam_pelajaran: this.inputClassHourEdit,
          jam_mulai: this.inputStartEdit.split('-')[1],
          tipe_model_jadwal: this.desc.tipe_model_jadwal,
        },
      })
        .then(_ => {
          this.loadingConfirmEdit = false
          this.$notification.success({
            message: 'Success',
            description:
              'Successfully edit schedule.',
          })
          // this.modalEdit = false
          window.location.reload()
        })
    },
    handleOkEdit() {
      this.modalEdit = true
    },
    handleCancelEdit() {
      this.idKelasEdit = null
      this.inputSubjectEdit = null
      this.inputDayEdit = null
      this.inputStartEdit = null
      this.inputClassHourEdit = null
      this.inputTeacherEdit = null
      this.inputClassEdit = null
      this.currentEditSlot = null
      this.positionEdit = null
      this.jamSelesaiEdit = null
      this.visibleEditSlot = false
    },
    showModal() {
      this.visible = true
      this.loadingAdd = true
      this.$store.dispatch('admin/FETCH_CLASS_AND_SUBJECT_BY_SLOT', {
        idGuru: this.$route.params.id,
        posisi: this.desc.position,
        hari: this.day,
      })
        .then(({ kelas, mapel }) => {
          // console.log(kelas)
          if (kelas.length === 0 || this.mapel === 0) {
            this.visible = false
            this.$notification.error({
              message: 'Sorry.',
              description:
                'All class already have schedule on that time.',
            })
          } else {
            this.kelas = kelas
            this.mapel = mapel
            this.loadingAdd = false
          }
        })
    },
    handleSelectClassHour(value) {
      if (this.inputClass && value === 2) {
        const addBreak = this.desc.position === 4 && value === 2 ? 15 : 0
        const jamMapelSelanjutnya = moment(this.desc.jamMulai, 'HH:mm').add((this.desc.durasi) + addBreak, 'minutes').format('HH:mm')
        this.$store.dispatch('admin/VALIDATION_CLASS_SLOT', {
          tipeModelJadwal: this.desc.tipe_model_jadwal,
          jamMulai: jamMapelSelanjutnya,
          jamPelajaran: value,
          idKelas: this.inputClass,
          posisi: this.desc.position === 4 ? this.desc.position + 2 : this.desc.position + 1,
          hari: this.day,
        })

          .then(data => {
            if (data) {
              // console.log(data)
              this.inputClassHour = value
              this.jamSelesai = moment(this.desc.jamMulai, 'HH:mm').add((this.desc.durasi * value) + addBreak, 'minutes').format('HH:mm')
            } else {
              this.inputClassHour = 1
              // console.log(this.inputClassHour)
              this.$notification.error({
                message: 'Error',
                description:
                  'That class already has schedule on next class hour',
              })
            }
          })
      } else {
        this.inputClassHour = value
        const addBreak = this.desc.position === 4 && value === 2 ? 15 : 0
        this.jamSelesai = moment(this.desc.jamMulai, 'HH:mm').add((this.desc.durasi * value) + addBreak, 'minutes').format('HH:mm')
      }
    },
    handleCancel() {
      this.inputClass = null
      this.inputSubject = null
      this.inputClassHour = 1
      this.jamSelesai = moment(this.desc.jamMulai, 'HH:mm').add(this.desc.durasi * 1, 'minutes').format('HH:mm')
      this.visible = false
      this.validationAdd = {
        class: false,
        subject: false,
      }
    },
    confirmAdd() {
      this.loadingConfirmAdd = true
      this.$store.dispatch('admin/CREATE_SLOT', {
        slot: {
          tipe_model_jadwal: this.desc.tipe_model_jadwal,
          id_guru: this.$route.params.id,
          id_mata_pelajaran: this.inputSubject,
          id_kelas: this.inputClass,
          hari: this.day,
          jam_mulai: this.desc.jamMulai,
          posisi: this.desc.position,
          jam_pelajaran: this.inputClassHour,
        },
      })
        .then(_ => {
          this.$notification.success({
            message: 'Success',
            description:
              'Successfully add new schedule.',
          })
          window.location.reload()
        })
    },
    handleCreate() {
      if (this.inputSubject === null || this.inputClass === null) {
        this.validationAdd = {
          subject: this.inputSubject === null,
          class: this.inputClass === null,
        }
        this.$notification.error({
          message: 'Error',
          description:
            'All field is required!',
        })
      } else {
        this.modalConfrim = true
      }
    },
    handleSelectSubject(value) {
      this.inputSubject = value
    },
    handleSelectClass(value) {
      if (this.inputClassHour === 2) {
        this.inputClass = value
        const addBreak = this.desc.position === 4 && value === 2 ? 15 : 0
        const jamMapelSelanjutnya = moment(this.desc.jamMulai, 'HH:mm').add((this.desc.durasi) + addBreak, 'minutes').format('HH:mm')
        this.$store.dispatch('admin/VALIDATION_CLASS_SLOT', {
          tipeModelJadwal: this.desc.tipe_model_jadwal,
          jamMulai: jamMapelSelanjutnya,
          idKelas: this.inputClass,
          posisi: this.desc.position === 4 ? this.desc.position + 2 : this.desc.position + 1,
          hari: this.day,
        })

          .then(data => {
            if (data) {
              // console.log(data)
              this.inputClass = value
            } else {
              this.inputClassHour = 1
              this.$notification.error({
                message: 'Error',
                description:
                  'That class already has schedule on next class hour',
              })
            }
          })
      } else {
        this.inputClass = value
      }
    },
  },
  computed: {
    classHours() {
      if (this.desc.position === 9 || (this.inputStartEdit && Number(this.inputStartEdit.split('-')[0]) === 9)) {
        return 1
      } else {
        return 2
      }
    },
    classes() {
      return this.$store.state.admin.classes
    },
    day() {
      switch (this.desc.day) {
        case 1:
          return 'Senin'
        case 2:
          return 'Selasa'
        case 3:
          return 'Rabu'
        case 4:
          return 'Kamis'
        case 0:
          return 'Jumat'
        default:
          return ''
      }
    },
  },
  created() {
    let countSlot = 0
    for (let i = 0; i < this.slots.length; i++) {
      const slot = this.slots[i]
      if (slot.id === this.desc.id) {
        countSlot++
      }

      if (countSlot > 1) {
        if (Number(this.desc.position) === Number(slot.posisi)) {
          this.isRepeated = true
        }
        break
      }
    }
  },
}
</script>
<style lang="scss">
.boxScheduleAdmin {
  .buttonSlot {
    font-size: 1rem !important;
  }

  .textButtonSlot:hover {
    text-decoration: underline;
  }

  .break {
    width: 100%;
    height: 100%;
    background-color: #d3d9e6;
    color: white;
    /* border-radius: 10px; */
    /* margin: -2.5px; */
    /* padding: 10px 16px; */
    /* box-shadow: 0px 0px 2px #00000029; */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .hoverSlot {
    cursor: pointer;
    width: 100%;
    height: 100%;
    background-color: #d3d9e6;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .boxSlot {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px 16px;
    cursor: pointer;
    box-shadow: 0px 0px 2px #00000029;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.5s;
  }

  .boxSlot:hover {
    transform: translate(0, -5px);
    box-shadow: 0px 0px 20px #0419301a;
    transition: all 0.5s;
  }

  .animationPlus {
    animation: animationFrames 1s ease 0s 1 normal forwards running;
    transform-origin: 50% 50%;
  }
  @keyframes animationFrames {
    0% {
      transform: translateX(-10px);
    }
    100% {
      transform: translateX(0px);
    }
  }
}
</style>
